import axios from 'axios';

import {
    CSNVenueSearchInput,
    CSNVenueProfileServiceInput,
    CSNVenueSearchOutput,
    CSNVenueProfile,
    CSNQueryParserInput,
    CSNQueryParserResult,
} from './types';
import { getCSNSettings } from './csnSettings';
import { getImplicitSearchInput } from './getImplicitSearchInput';

const buildServiceUrl = (host: string, path: string, envKey?: string, envVal?: string) => {
    const url = new URL(path, host);
    if (envKey && envVal) {
        url.searchParams.set(envKey, envVal);
    }

    return url.href;
};

class CSNServiceConsumer {
    private readonly _settings: ReturnType<typeof getCSNSettings>;

    constructor() {
        this._settings = { ...getCSNSettings() };
    }

    getVenueProfiles(input: CSNVenueProfileServiceInput): Promise<Array<CSNVenueProfile>> {
        if (!input.venueIds.length) {
            return Promise.resolve([]);
        }

        const serviceUrl = buildServiceUrl(
            this._settings.CSN_VENUE_PROFILE_HOST,
            this._settings.CSN_VENUE_PROFILE_PATH,
            'env',
            this._settings.CSN_VENUE_PROFILE_ENV
        );

        return axios.post<Array<CSNVenueProfile>>(serviceUrl, input.venueIds).then((resp) =>
            resp.data.map((v: CSNVenueProfile) => ({
                amenities: v.amenities,
                basicProfile: v.basicProfile,
                closestAirports: v.closestAirports,
                guestRoom: v.guestRoom,
                highlights: v.highlights,
                images: v.images,
                meetingRooms: v.meetingRooms,
            }))
        );
    }

    queryParser(input: CSNQueryParserInput): Promise<CSNQueryParserResult> {
        const serviceUrl = buildServiceUrl(
            this._settings.CSN_QUERY_PARSER_HOST,
            this._settings.CSN_QUERY_PARSER_PATH,
            'env',
            this._settings.CSN_QUERY_PARSER_ENV
        );

        const data = {
            ...input,
            language: input.language || 'EN',
            region: input.region || 'US',
        };
        return axios.post<CSNQueryParserResult>(serviceUrl, data).then((resp) => resp.data);
    }

    venuesSearch(input: CSNVenueSearchInput) {
        const serviceUrl = buildServiceUrl(
            this._settings.CSN_VENUE_SEARCH_HOST,
            this._settings.CSN_VENUE_SEARCH_PATH,
            'env',
            this._settings.CSN_VENUE_SEARCH_ENV
        );

        const postData = {
            ...getImplicitSearchInput(input.geoFilter, input.boundingBox),
            from: input.pageFilter.from,
            includeRanges: false,
            queryRequest: {
                queryString: input.query,
            },
            size: input.pageFilter.size,
        };

        if (input.guestRoomsFilter) {
            (postData.filters as Array<any>).push({
                maximum: input.guestRoomsFilter.max,
                measurementUnit: 'SQUARE_FEET',
                minimum: input.guestRoomsFilter.min,
                type: 'NUMBER_OF_SLEEPING_ROOMS',
            });
        }

        if (input.amenitiesFilter) {
            postData.filters.push({
                occurrenceType: 'MUST',
                type: 'AMENITIES',
                values: input.amenitiesFilter.amenities,
            });
        }

        if (input.areasFilter) {
            postData.filters.push({
                occurrenceType: 'SHOULD',
                type: 'AREA_TYPE',
                values: input.areasFilter.areas,
            });
        }

        if (input.radius >= 0) {
            const maxDistanceFilter = postData.filters.find((filter) => filter.type.toUpperCase() === 'MAXIMUM_DISTANCE') as {
                maximum: number;
            };
            if (maxDistanceFilter && maxDistanceFilter.maximum > 0) {
                maxDistanceFilter.maximum = input.radius || 1;
            }
        }

        postData.filters.push({
            occurrenceType: 'MUST_NOT',
            type: 'VENUE_TYPE',
            values: ['CVB'],
        });

        return axios.post(serviceUrl, postData).then((resp) => {
            // CSN service always returns some ads as part of the in-line search results
            // this is indicated by a value in the "featuredLevel" prop on venue data
            const data = resp.data as CSNVenueSearchOutput;
            const { venueList } = data.searchResult;

            const filteredVenues = venueList.filter((v) => !v.featuredLevel);

            return {
                ...data,
                searchResult: {
                    ...data.searchResult,
                    venueList: filteredVenues,
                },
            };
        });
    }
}

export const CSNServiceApi = new CSNServiceConsumer();
