import { WsApiClientRegistry } from '../ApiClientRegistry';
import { Venues, Appointments } from '../types';
import { ApiConsumerBase } from './ApiConsumerBase';

class VendorsConsumer extends ApiConsumerBase {
    venueDetails(venueId: number, queryParams?: {}) {
        return this.ApiWorker.retrieve<Venues.VenueDetails>(`/vendors-full/${venueId}`, { urlParams: queryParams });
    }

    venueDetailsBulk(venuesIds: number[], queryParams?: {}) {
        const promises = venuesIds.map((vid) =>
            this.ApiWorker.retrieve<Venues.VenueDetails | Venues.UnlistedVenueDetails>(`/vendors-full/${vid}`, queryParams)
        );
        return Promise.all(promises) as Promise<Array<Venues.VenueDetails | Venues.UnlistedVenueDetails>>;
    }

    personalizedVenueDetails(venueId: number) {
        return this.ApiWorker.retrieve<Venues.VenueDetailsPersonalized>(`/vendors-personalized/${venueId}`, undefined);
    }

    setFavorite(venueIds: number[], favoriteType?: Venues.FavoriteType) {
        return this.ApiWorker.create(`/vendors/set-favorites`, {
            favorite_type: favoriteType,
            vendor_ids: venueIds,
        });
    }

    removeFavorite(venueIds: number[]) {
        return this.ApiWorker.create(`/vendors/unset-favorites`, {
            is_favorite: false,
            vendor_ids: venueIds,
        });
    }

    logVenueMetric(venueId: number, logType: Venues.VenueMetricLogType, metricsMetatype: Venues.VenueMetricsMetaType) {
        return this.ApiWorker.create(`/vendors/${venueId}/log-metric`, { log_type: logType, metrics_metatype: metricsMetatype });
    }

    /**
     * Send an inquiry, can be authenticated or unauthenticated
     * @param {number} venueId - The ID for the venue we want to send the inquiry to
     * @param {InquiryRequestParams} params - The inqiury parameters
     */
    sendInquiry(venueId: number, params: Appointments.InquiryRequestParams) {
        return this.ApiWorker.create(`/vendors/${venueId}/send-email-inquiry`, params);
    }

    sendInquiryHotel(params: Appointments.InquiryHotelParams) {
        return this.ApiWorker.create(`/csn/rfp`, params);
    }

    /**
     * Send an inquiry, can be authenticated or unauthenticated
     * @param {number} venueId - The ID for the venue we want to send the inquiry to
     * @param {QuoteRequestParams} params - The inqiury parameters
     */
    sendQuoteRequest(venueId: number, params: Appointments.QuoteRequestParams) {
        return this.ApiWorker.create(`/vendors/${venueId}/send-email-inquiry`, params);
    }

    /**
     * Send an inquiry, can be authenticated or unauthenticated
     * @param {MassInquiryRequestParams} params - The inqiury parameters
     */
    sendMassInquiries(params: Appointments.MassInquiryRequestParams) {
        return this.ApiWorker.create(`/vendors/send-mass-email-inquiries`, params);
    }

    /**
     * Send an inquiry with user token
     * @param {MassInquiryRequestViaTokenParams} params - The inqiury parameters
     */
    sendMassInquiriesViaToken(params: Appointments.MassInquiryRequestViaTokenParams) {
        return this.ApiWorker.create(`/vendors/send-mass-email-inquiries-via-token`, params);
    }

    /**
     * Get list of venue recommendations
     * @param {VenueRecommendationsQueryParams} params - Query params that filter the results set
     */
    getVenueRecommendations(params: Venues.VenueRecommendationsQueryParams): Promise<Venues.VenueRecommendation[]> {
        const data = {
            ...params,
            seed_ids: params.seed_ids,
            ...(params.exclude_ids &&
                params.exclude_ids.length && {
                    exclude_ids: Array.from(new Set<number>(params.exclude_ids)),
                }),
        };
        return this.ApiWorker.create('/venue-recommendations', data);
    }

    /**
     * Get list of venue recommendations
     * @param {VenueRecommendationsQueryParams} params - Query params that filter the results set
     */
    getHotelRecommendations(params: Venues.HotelRecommendationsQueryParams) {
        const data = { ...params, ws_seed_ids: params.ws_seed_ids.join(',') };
        return this.ApiWorker.retrieve<Array<Venues.HotelRecommendation>>('/csn/hotels', { urlParams: data });
    }

    /**
     * Log venue recommendation display impression
     */
    logVenueRecomendationsImpression(data: Venues.VenueRecommendationsImpression) {
        return this.ApiWorker.create('/log-recommendation-impressions', data);
    }

    /**
     * Log venue recommendation clickthrough
     */
    logVenueRecomendationsClickthrough(data: Venues.VenueRecommendationsClickthrough) {
        return this.ApiWorker.create('/log-recommendation-clickthrough', data);
    }

    /**
     * Report non-compliant images
     */
    reportImages(data: Venues.ReportDMCARequest) {
        return this.ApiWorker.create('/venues/report-dmca', data);
    }

    /**
     * Send vendor signup load (partial signup from abandonment flow)
     */
    vendorSignupLead(data: Venues.VendorSignupLead) {
        return this.ApiWorker.create('/vendor-leads', data);
    }

    /**
     * Get venues data for mass inquiry page
     */
    getVenuesDataForInquiry(venueIds: number[]) {
        return this.ApiWorker.create<Array<Venues.VenueRecommendation>, { vendor_ids: Array<number> }>('/vendors/get-mass-inquiry-data', {
            vendor_ids: venueIds,
        });
    }

    /**
     * Log hotel recommendation impression event
     * @param data contextual information about the impression
     */
    logHotelRecommendationImpression(data: Venues.HotelRecommendationImpressionEventData) {
        return this.ApiWorker.create('/user-events/hotel-impressions', {
            date_occurred: new Date().toISOString(),
            event_data: data,
            event_type: Venues.UserEventType.CSN_HOTEL_ROOM_RECS_SEEN,
        });
    }

    getReviews(venueId: number, queryParams: Venues.GetReviewsQueryParam) {
        return this.ApiWorker.retrieve<Venues.OverallReviews>(`/csn/reviews/${venueId}`, { urlParams: queryParams });
    }

    fetchUnreadReviewsCount() {
        return this.ApiWorker.retrieve(`/csn/unread-reviews-count`);
    }

    fetchUserVenues(queryParams: Venues.GetUserVenuesParam) {
        return this.ApiWorker.retrieve<Venues.UserVenues>(`csn/reviews/user-venues`, { urlParams: queryParams });
    }

    saveReview(data: Venues.SaveReview) {
        return this.ApiWorker.create('csn/review', data);
    }
}

export const VendorsApi = new VendorsConsumer();
WsApiClientRegistry.register(VendorsApi);
