export namespace Venues {
    export enum VenueService {
        indoorReception = 'indoor_reception',
        outdoorReception = 'outdoor_reception',
        indoorCeremony = 'indoor_ceremony',
        outdoorCeremony = 'outdoor_ceremony',
        cocktailHour = 'cocktail_hour',
        privateCeremony = 'private_ceremony',
    }

    export enum VenueRDService {
        indoorRehearsalDinner = 'indoor_rehearsal_dinner',
        outdoorRehearsalDinner = 'outdoor_rehearsal_dinner',
    }

    export enum VenueCatering {
        BYO = 'byo',
        list = 'list',
        provided = 'provided',
    }

    export enum VenueAlcohol {
        byo = 'byo',
        provided = 'provided',
        providedByDrink = 'provided_by_drink',
    }

    export enum VenueBudgetCategory {
        ceremonyAndReception = 1,
        ceremonyOnly,
        receptionOnly,
        privateCeremonyOnly,
    }

    export interface VenueAward {
        name: string;
        description: string;
        image_url: string;
        page_url: string;
    }

    export interface VenuePackage {
        id: number;
        title: string;
        starting_price: string;
        description: string;
        content_link: string;
    }

    export interface VenueVideo {
        id: number;
        name: string;
        embedded_video: string;
    }

    export enum VenueHighlightType {
        awards_winner = 'awards_winner',
        discount_available = 'discount_available',
        accommodation_included = 'accommodation_included',
        indoor_and_outdoor_spaces = 'indoor_and_outdoor_spaces',
        food_and_beverage_provided = 'food_and_beverage_provided',
        byo = 'byo',
        upgraded_rentals_included = 'upgraded_rentals_included',
        standard_rentals_included = 'standard_rentals_included',
        valet_parking = 'valet_parking',
    }

    export interface VenueHighlight {
        name: VenueHighlightType;
        label: string;
        icon_url: string;
    }

    export interface VenueBudgetRange {
        min: number;
        max: number;
        capacity: number;
        service_category: VenueBudgetCategory;
    }

    export interface VenueDiscount {
        id: number;
        title: string;
        description: string;
        promo_code: string | null;
    }

    export interface VenueUpcomingEvent {
        id: number;
        name: string;
        website: string;
        start_date: string;
        end_date: string;
        start_time: string;
        end_time: string;
    }

    export interface VenueDetails {
        id: number;
        name: string;
        csn_venue_id: string;

        market: string;

        address_1: string | null;
        address_2: string | null;
        city: string | null;
        state: string | null;
        province: string | null;
        country: string;

        max_capacity: number;

        catering: VenueCatering[];
        alcohol: VenueAlcohol[];
        packages: VenuePackage[];
        is_favorite: boolean;
        is_priced: boolean;
        is_destination: boolean;

        upcoming_events: VenueUpcomingEvent[];

        awards: VenueAward[];
        images: {
            id: number;
            image: string;
            crop_image: string;
            main_image: string;
            mini_thumbnail: string;
            thumbnail: string;
            portrait_image: string;
            photo_credit: string;
            storage_url: string;
            order: number;
            provided_by_venue: boolean;
            photo_source: string;
            landscape_image: string;
        }[];
        description: string;
        description_rds: string | null;
        services: {
            id: number;
            name: string;
            service_type: VenueService;
            service_type_display_name: string;
            capacity: number;
        }[];
        services_rds: {
            id: number;
            name: string;
            service_type: VenueRDService;
            service_type_display_name: string;
            capacity: number;
        }[];
        cost_min: number;
        cost_max: number;
        amenities: {
            id: number;
            type: string;
            name: string;
        }[];
        state_abbr: string | null;

        special_restrictions: {
            id: number;
            type: string;
            name: string;
        }[];

        styles: {
            id: number;
            type: string;
            name: string;
            long_name: string;
        }[];
        last_updated_date: string;

        phone_number: string;
        region: string;
        website: string | null;
        ceremony: string;
        reception: string;
        wedding_spot_awards_years: number[];
        hide_wedding_costs: boolean;
        hide_guest_rooms: boolean;
        time_restrictions_str: string;
        rental_fee_description: string;
        availability: Array<Array<string>> | null;
        appointment_blackout_days: number[] | null;
        capacity: string[];
        timeRestrictions: string[];
        longitude: number;
        latitude: number;
        videos: VenueVideo[];
        no_longer_doing_weddings: boolean;
        require_inquiry_guest_count: boolean;
        inquiry_attribution_img_url: string | null;
        facebook_link: string | null;
        instagram_link: string | null;
        pinterest_link: string | null;
        venue_highlights: VenueHighlight[];
        custom_venue_highlights: string[];
        budget_range: VenueBudgetRange | null;
        discount: VenueDiscount | null;
        disable_recommendations: boolean;
        test_flag: boolean;
        test_profile_images_lazy_load: boolean;
        is_not_available: boolean;
        meta_description: string;
        peak_season?: number[];
        zip_code: string;
        contract_package?: string;

        // RDV Fields
        has_rds: boolean;
        has_weddings: boolean;

        // flag to force v2
        force_v2: boolean;

        // meta
        schema_org?: string;
        page_title?: string;
        canonical_url?: string;
    }

    export interface UnlistedVenueDetails {
        id: number;
        name: string;
        market: string;
        region: string;
        is_not_available: boolean;
        canonical_url: string;
        hide_guest_rooms?: boolean;

        // flag to force v2
        force_v2: boolean;

        // meta
        schema_org?: string;
        page_title?: string
        meta_description?: string;
    }

    export interface VenueDetailsQueryParams {
        preview?: '1';
        show_unapproved_images?: '1';
        rdv?: '1';
    }

    export interface VenueProfile extends VenueDetails {
        activeSlide: number;
        activeTabIndex: number;
        isMoreTabOpen: boolean;
        isPhoneShown: boolean;
    }

    export enum VenueProfileNotificationType {
        booked = 'booked',
        looking = 'looking',
    }

    export interface VenueProfileNotification {
        message: string;
        type_lbl: VenueProfileNotificationType;
    }

    /**
     * Personalized venue details
     */
    export interface VenueDetailsPersonalized {
        is_favorite: boolean;
        estimate_completed: boolean;
        notifications: VenueProfileNotification[];
        spot_estimate: number | null;
        test_flag: boolean;
        show_additional_price_venue_button: boolean;
        guest_rooms_recommendations: boolean;
        ws_rfp_form: boolean;
        test_sleeping_rooms_on_venue_profile: boolean;
        test_active_travel_notifications: boolean;
        price_this_venue_test_value: 'A' | 'B' | 'C';
        test_guest_rooms_intent_cta: 'A' | 'B';
        intent_CTA_on_post_outreach_step_1: 'A' | 'B';
        test_outreach_reduce_pt_2: 'A' | 'B';
        intent_cta_step_removed: 'A' | 'B';
    }

    export enum VenueMetricLogType {
        PROFILE_VIEW = 'profile_view',
        WEBSITE_CLICK = 'website_click',
        PHONE_CLICK = 'phone_click',
    }

    export enum FavoriteType {
        WEDDING = 1,
        REHEARSAL_DINNERS,
    }

    export enum RecommendationType {
        WEDDING = 1,
        REHEARSAL_DINNERS,
    }

    /**
     * Represents the data necessary to render a single venue recommendation
     */
    export interface VenueRecommendation {
        id: number;
        rec_type: RecommendationType;
        name: string;
        region: string;
        market: string;
        city: string;
        state: string;
        thumbnail_url: string;
        main_image_url: string;
        profile_url: string;
        estimate_url?: string;

        estimate_total?: number;
        budget_capacity?: number;
        budget_min?: number;
        budget_max?: number;
        budget_category?: number;
    }

    export interface VenueRecommendationsQueryParams {
        seed_ids: number[];
        appointment_id?: number;
        count?: number;
        budget_min?: number;
        budget_max?: number;
        budget_capacity?: number;
        budget_category?: number;
        exclude_ids?: number[];
        rdvs?: 1;
    }

    /**
     * Represents the data necessary to render a single hotel recommendation
     */
    export interface HotelRecommendation {
        csn_id: string;
        name: string;
        city: string;
        state: string;
        image: string;
        distance: number;
        type?: string;
    }

    export interface HotelRecommendationsQueryParams {
        ws_seed_ids: number[];
        limit?: number;
    }

    /**
     * Enum that describes that the inventory type for the recommendaiton impression
     */
    export enum InventoryType {
        INV_SUBTYPE_REC_APPT_CONFIRMATION = 1,
        INV_SUBTYPE_REC_VENUEDETAILS = 5,
    }

    /**
     * Metric metatype
     */
    export enum VenueMetricsMetaType {
        WEDDING_METRIC = 1,
        RD_METRIC = 2,
    }

    /**
     * Data to record for a venue recommendation impression
     */
    export type VenueRecommendationsImpression = {
        venue_ids: number[];
        venue_recommended_for_id: number;
        recommended_from: InventoryType;
        metrics_metatype: VenueMetricsMetaType;
    };

    /**
     * Clickthrough metrics data
     */
    export type VenueRecommendationsClickthrough = {
        venue: number;
        position: number;
        recommended_from: InventoryType;
        metrics_metatype: VenueMetricsMetaType;
    };

    /**
     * DMCA takedown reasons
     */
    export enum DMCATakedownReason {
        inappropriate_content = 'inappropriate_content',
        not_my_venue = 'not_my_venue',
        infringes_my_right = 'infringes_my_right',
    }

    /**
     * DMCA takedown api request data
     */
    export interface ReportDMCARequest {
        venue_id: number;
        report_reason: DMCATakedownReason;
        email: string;
        message: string;
    }

    /**
     * Vendor signup lead generation API data
     */
    export interface VendorSignupLead {
        first_name: string;
        last_name: string;
        email: string;
    }

    /**
     * User events
     */
    export enum UserEventType {
        CSN_HOTEL_ROOM_RECS_SEEN = 24,
    }

    export interface UserEvent<D, E> {
        event_data: D;
        event_type: E;
        date_occurred: string;
    }

    export enum HotelRecommendationImpressionSource {
        INQUIRY_MODAL = 1,
        EMAIL = 2,
    }

    export interface HotelRecommendationImpressionEventData {
        ws_venue_id: number | null;
        csn_venue_ids: string[];
        source: HotelRecommendationImpressionSource;
    }

    export interface HotelRecommendationImpressionEvent
        extends UserEvent<HotelRecommendationImpressionEventData, UserEventType.CSN_HOTEL_ROOM_RECS_SEEN> {}

    export interface ReviewProps {
        isNew?: boolean;
        rating: {
            stars: number;
            readonly: boolean;
            name: string;
        };
        review: {
            author: string;
            reviewId: string;
            submittedTimestamp: string;
            text: string;
        };
        showAsCard?: boolean;
        showTimestamp?: boolean;
        isLast?: boolean;
    }

    export interface OverallReviews {
        overallRating?: number;
        totalCount?: number;
        data: ReviewProps[];
        newReviewsCount?: number;
    }

    export interface GetReviewsQueryParam {
        sort_order?: 'ASC' | 'DESC';
        size?: number;
        offset?: number;
        approval_statuses?: 'APPROVED' | 'DENIED' | 'PENDING';
    }

    export interface ReviewUnreadCount {
        success: boolean;
        unread_count: number;
    }
    export interface GetUserVenuesParam {
        id: string;
    }

    export interface UserVenues {
        reviewAdded: boolean;
        user: {
            firstname: string;
        };
        venues: { id: number; name: string }[];
    }

    export interface SaveReview {
        overall_rating: number;
        review_text: string;
        venue_id: number;
        ws_user_email_id: string;
    }
}
