import { WsApiClientRegistry } from '../ApiClientRegistry';
import { Auth } from '../types';
import { ApiDetailResponse, AuthApi } from '../types/authApi';
import { ApiConsumerBase } from './ApiConsumerBase';
import { getCookieValue } from '../ApiWorker/CookieWorker';

class AuthConsumer extends ApiConsumerBase implements AuthApi {
    
    login(userName: string, password: string) {
        return new Promise<void>((resolve, reject) => {
            this.ApiWorker.create<void, { email: string; password: string }>('/sign-in', { email: userName, password })
                .then((res) => {
                    const cookie = getCookieValue('csrftoken');
                    if (cookie) {
                        WsApiClientRegistry.configure({
                            headers: {
                                ['X-CSRFToken']: cookie,
                            },
                            oauthToken: undefined,
                        });
                    }
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    isLoggedIn(queryParams?: { [key: string]: any }) {
        return this.ApiWorker.retrieve<'authed' | 'anonymous'>('/auth-status', { urlParams: queryParams });
    }

    userInfo(queryParams?: { [key: string]: any }) {
        return this.ApiWorker.retrieve<Auth.UserInfo>('/user', { urlParams: queryParams });
    }

    checkEmailAvailable(email: string) {
        return this.ApiWorker.create<void, { email: string }>('/email-check', { email });
    }

    connectAccount(password: string) {
        return this.ApiWorker.create<void, { password: string }>('/connect-account', { password });
    }

    vendorSignup(data: Auth.VendorSignupData) {
        return this.ApiWorker.create<void, Auth.VendorSignupData>('/vendor-sign-up', data);
    }

    createAccount(request: Auth.CreateAccountRequest) {
        return new Promise<ApiDetailResponse>((resolve, reject) => {
            this.ApiWorker.create<ApiDetailResponse, Auth.CreateAccountRequest>('/sign-up', request, { 'X-Ws-AttachSession': 'True' })
                .then((response) => {
                    const cookie = getCookieValue('csrftoken');
                    if (cookie) {
                        WsApiClientRegistry.configure({
                            headers: {
                                ['X-CSRFToken']: cookie,
                            },
                            oauthToken: undefined,
                        });
                    }
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    /**
     * This method calls the /logout/ post endpoint of django server
     * and redirects the user to home page after successful logout.
     * Since the /logout/ endpoint expects the csrftoken in the request body,
     * we are first extracting csrftoken from the cookies and adding it to the request body.
     */
    logout () {
        const token = getCookieValue('csrftoken');
        const formData = new FormData();
        formData.append('csrfmiddlewaretoken', token as string);
        fetch('/logout/', { method: 'POST', body: formData, redirect: 'follow' })
            .then(() => {
                window.location.href = '/';
            })
            .catch(error => {
                // using console.log for now until logger is configured
                console.log('Failed to logout ', error);
            });
        return Promise.resolve();
    }

    extendSession(){
        return this.ApiWorker.retrieve<void>('/session-extend');
    }
}

export const AuthProvider = new AuthConsumer();
WsApiClientRegistry.register(AuthProvider);
