import { ApiClientConfiguration } from './types';
/* eslint-disable no-console */
import { AuthScheme } from '../types/authApi';

export type HoganConfig = {
    csn: {
        search_service: {
            base_url: string,
            env: string

        },
        venue_profile_service: {
            base_url: string
            env: string
        }
    },
    ws_api_client: {
        base_url: string;
        authentication_scheme: 'CSRF' | 'OAUTH';
    };
};
export const defaultConfiguration = (): ApiClientConfiguration => {    
    const HOGAN_CONFIG: HoganConfig =
        typeof process.env.HOGAN_CONFIG === 'string'
            ? (JSON.parse(process.env.HOGAN_CONFIG as string) as unknown as HoganConfig)
            : (process.env.HOGAN_CONFIG as unknown as HoganConfig);
    return {
        apiBaseUrl: `${HOGAN_CONFIG.ws_api_client.base_url}/api/v1`,
        apiHostUrl: HOGAN_CONFIG.ws_api_client.base_url,
        authenticationScheme: HOGAN_CONFIG.ws_api_client.authentication_scheme === 'OAUTH' ? AuthScheme.OAUTH : AuthScheme.CSRF,
    };
};
