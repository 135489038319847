/* Types of appointments, inquiries, quote requests, phone consults, etc. */
export namespace Appointments {
    export enum ContactReasons {
        OTHER = 200,
        NEED_PRICE = 201,
        CHECK_AVAILABILITY = 202,
        WEDDING_PACKAGE = 203,
        GENERAL_QUESTION = 204,
        REHEARSAL_DINNER_INQUIRY = 205,
        BRIDAL_SHOWER_INQUIRY = 206,
    }

    export enum OutreachTypes {
        WEDDING_FLOW = 1,
        RD_FLOW = 2,
    }

    export enum SleepingRoomOccupancy {
        BLANK = 'BLANK',
        SINGLE = 'SINGLE',
        DOUBLE = 'DOUBLE',
    }

    export enum SleepingRoom {
        ANY = 'ANY',
        SINGLE = 'SINGLE',
        DOUBLE = 'DOUBLE',
        SUITE = 'SUITE',
    }

    export interface InquiryRequestParams {
        contact_reason: ContactReasons;
        contract_reason_other?: string;
        message: string;
        phone_number?: string;
        outreach_type?: OutreachTypes;
        tos_shown?: boolean;
        widget?: boolean;
    }

    export interface SleepingRoomDetail {
        room_type: SleepingRoom;
        quantity: number;
        occupancy_type: SleepingRoomOccupancy;
    }

    export interface SleepingRoomBlock {
        day_number: number;
        room_details: SleepingRoomDetail[];
    }

    export type RFPleadSource = 'post outreach' | 'profile page' | 'room blocks search';

    export interface InquiryHotelParams {
        guest_rooms_per_night: number;
        arriving_on: string;
        departing_on: string;
        response_due_date: string;
        phone: string;
        city: string;
        country: string;
        additional_info: string;
        csn_venue_ids: string[];
        inquiry_id: string[];
        email: string[];
        first_name: string[];
        last_name: string[];
        sleeping_room_blocks?: SleepingRoomBlock[];
        lead_source: RFPleadSource;
    }

    export interface QuoteRequestParams extends InquiryRequestParams {
        guest_count?: number;
        budget_range_min?: number;
        budget_range_max?: number;
        dates_flexible?: boolean;
        preferred_date_1?: string;
        preferred_date_2?: string;
    }

    export interface UnauthenticatedInquiryRequestParams extends InquiryRequestParams {
        email: string;
        first_name: string;
        last_name: string;
    }

    export interface UnauthenticatedQuoteRequestParams extends QuoteRequestParams {
        email: string;
        first_name: string;
        last_name: string;
    }

    export type AppointmentCreationSource = 'web' | 'web-mobile' | 'api' | 'mass-api' | 'mass-api-via-token' | 'shopping-cart';

    export interface MassInquiryRequestParams {
        vendor_ids: number[];
        message: string;
        creation_source?: AppointmentCreationSource;
        venues_analytics?: {};
    }

    export interface MassInquiryRequestViaTokenParams extends MassInquiryRequestParams {
        token: string;
    }

    export enum AppointmentRequestStatus {
        // appointment has no activity
        APPOINTMENT_NOT_ACTIVE = 'appointment_not_active',

        // a quote was requested from the vendor
        QUOTE_REQUESTED = 'quote_requested',

        // the user sent the vendor a message
        VENUE_CONTACTED = 'venue_contacted',

        // the appointment is for a phone consultation (Hawaii vendors only)
        PHONE_CONSULT = 'phone_consult',

        // an appointment was requested for a site tour by the user.
        APPOINTMENT_REQUESTED = 'appointment_requested',

        // the vendor has replied to the appointment request with a message
        APPOINTMENT_VENDOR_REPLIED = 'appointment_vendor_replied',

        // an appointment was scheduled with the vendor
        APPOINTMENT_SCHEDULED = 'appointment_scheduled',

        // the appointment has been confirmed by the vendor
        APPOINTMENT_CONFIRMED = 'appointment_confirmed',

        // the appointment was completed by the vendor
        APPOINTMENT_SELF_COMPLETED = 'appointment_self_completed',

        // the appointment was completed
        APPOINTMENT_COMPLETED = 'appointment_completed',

        // a wedding was booked
        APPOINTMENT_BOOKED = 'appointment_booked',

        // the appointment was canceled by the vendor
        APPOINTMENT_VENDOR_CANCELED = 'appointment_vendor_canceled',

        // the appointment was canceled by the user
        APPOINTMENT_USER_CANCELED = 'appointment_user_canceled',
    }

    export interface AppointmentRequest {
        id: number;
        vendor: number;
        status: AppointmentRequestStatus;
        requested_date: string;
        spot_estimate: number;
        appointment_date: string | null;
        wedding_date: string | null;
    }
}
