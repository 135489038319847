import { WsApiClientRegistry } from '../ApiClientRegistry';
import { DWT } from '../types';
import { ApiConsumerBase } from './ApiConsumerBase';

class DwtConsumer extends ApiConsumerBase {
    sendQuoteRequest(params: DWT.DWTQuoteRequestParams) {
        return this.ApiWorker.create(`/dw-inquiry`, params);
    }
}

export const DwtApi = new DwtConsumer();
WsApiClientRegistry.register(DwtApi);
