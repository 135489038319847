/**
 * Types related to navigational endpoints e.g. headers, landing pages, etc.
 */
export namespace Navigation {
    /**
     * Different types of site header links, one for each column
     */
    export enum SiteHeaderLinkType {
        popular = 'popular',
        style = 'style',
    }

    /**
     * Header link metadata
     */
    export interface SiteHeaderLink {
        name: string;
        type: SiteHeaderLinkType;
        url: string;
        category?: string;
        show_on_mobile?: boolean;
    }

    /**
     * Breadcrumb data
     */
    export interface Breadcrumb {
        name: string;
        url: string;
    }

    /**
     * Data for displaying the header and subheader navs
     */
    export interface SiteHeaderData {
        links: SiteHeaderLink[];
        breadcrumbs?: Breadcrumb[];
        return_to_results_link: string;
    }
}
