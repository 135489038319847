import { ApiConsumerBase } from './ApiConsumers/ApiConsumerBase';
import { ApiClientConfiguration, ApiWorker, defaultConfiguration, IApiWorker } from './ApiWorker';

class ApiClientRegistry {
    private _consumers = new Array<ApiConsumerBase>();

    private _apiWorker: IApiWorker;

    constructor() {
        this._apiWorker = new ApiWorker(defaultConfiguration());
    }

    register(consumer: ApiConsumerBase) {
        this._consumers.push(consumer);
        consumer.configure(this._apiWorker);
    }

    configure(config?: Partial<ApiClientConfiguration>) {
        const configuration = Object.assign(defaultConfiguration(), config);
        this._apiWorker = new ApiWorker(configuration);
        this._consumers.forEach((consumer) => consumer.configure(this._apiWorker));
    }

    get Configuration() {
        return this._apiWorker.apiConfiguration();
    }
}

export const WsApiClientRegistry = new ApiClientRegistry();
