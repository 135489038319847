export enum CSNAmenity {
    CONCIERGE_SERVICES = 'CONCIERGE_SERVICES',
    INTERNET = 'INTERNET',
    ROOM_SERVICE = 'ROOM_SERVICE',
    LAUNDRY = 'LAUNDRY',
    STREET_PARKING = 'STREET_PARKING',
    COMPLIMENTARY_PARKING_PROVIDED = 'COMPLIMENTARY_PARKING_PROVIDED',
    BUSINESS_CENTER = 'BUSINESS_CENTER',
    FREE_AIRPORT_SHUTTLE = 'FREE_AIRPORT_SHUTTLE',
    HEALTH_CLUB = 'HEALTH_CLUB',
}

export enum CSNAreaType {
    AIRPORT = 'AIRPORT',
    INTERSTATE = 'INTERSTATE',
    RESORT = 'RESORT',
    SMALL_METRO_TOWN = 'SMALL_METRO_TOWN',
    SUBURBAN = 'SUBURBAN',
    URBAN = 'URBAN',
}

export enum CSNVenueRating {
    BLANK = 'BLANK',
    ONE_DIAMOND = 'ONE_DIAMOND',
    TWO_DIAMONDS = 'TWO_DIAMONDS',
    THREE_DIAMONDS = 'THREE_DIAMONDS',
    FOUR_DIAMONDS = 'FOUR_DIAMONDS',
    FIVE_DIAMONDS = 'FIVE_DIAMONDS',
    FORBES_ONE_STAR = 'FORBES_ONE_STAR',
    FORBES_TWO_STAR = 'FORBES_TWO_STAR',
    FORBES_THREE_STAR = 'FORBES_THREE_STAR',
    FORBES_FOUR_STAR = 'FORBES_FOUR_STAR',
    FORBES_FIVE_STAR = 'FORBES_FIVE_STAR',
    ONE_STAR = 'ONE_STAR',
    ONE_STAR_AND_HALF = 'ONE_STAR_AND_HALF',
    TWO_STAR = 'TWO_STAR',
    TWO_STAR_AND_HALF = 'TWO_STAR_AND_HALF',
    THREE_STAR = 'THREE_STAR',
    THREE_STAR_AND_HALF = 'THREE_STAR_AND_HALF',
    FOUR_STAR = 'FOUR_STAR',
    FOUR_STAR_AND_HALF = 'FOUR_STAR_AND_HALF',
    FIVE_STAR = 'FIVE_STAR',
    FIVE_STAR_AND_HALF = 'FIVE_STAR_AND_HALF',
    CATALUNYA_ONE_STAR = 'CATALUNYA_ONE_STAR',
    CATALUNYA_TWO_STAR = 'CATALUNYA_TWO_STAR',
    CATALUNYA_THREE_STAR = 'CATALUNYA_THREE_STAR',
    CATALUNYA_FOUR_STAR = 'CATALUNYA_FOUR_STAR',
    CATALUNYA_FOUR_STAR_SUPERIOR = 'CATALUNYA_FOUR_STAR_SUPERIOR',
    CATALUNYA_FIVE_STAR = 'CATALUNYA_FIVE_STAR',
    CATALUNYA_LUXE = 'CATALUNYA_LUXE',
}

export enum CSNVenueTag {
    HAS_PROMOTIONS = 'HAS_PROMOTIONS',
    ENVIRONMENTALLY_FRIENDLY = 'ENVIRONMENTALLY_FRIENDLY',
    TOP_AWARDED = 'TOP_AWARDED',
    HAS_NEED_DATES = 'HAS_NEED_DATES',
    PASSKEY_ENABLED = 'PASSKEY_ENABLED',
    SOCIAL_TABLES_ENABLED = 'SOCIAL_TABLES_ENABLED',
    SAFETY_INFO = 'SAFETY_INFO',
}

export enum CSNVenueType {
    BLANK = 'BLANK',
    HOTEL = 'HOTEL',
    RESORT = 'RESORT',
    LUXURY_HOTEL = 'LUXURY_HOTEL',
    BOUTIQUE_HOTEL = 'BOUTIQUE_HOTEL',
}

export enum CSNDiamondLevel {
    BASIC = 'BASIC',
    HALF_DIAMOND = 'HALF_DIAMOND',
    ONE_DIAMOND = 'ONE_DIAMOND',
    TWO_DIAMOND = 'TWO_DIAMOND',
    THREE_DIAMOND = 'THREE_DIAMOND',
    FOUR_DIAMOND = 'FOUR_DIAMOND',
}

/**
 * Abstracted interface for CSN venue search
 */
export interface CSNVenueSearchInput {
    // Location search term (e.g. 'San Francisco, CA', 'Kohl Mansion Burlingame')
    query: string;

    geoFilter: CSNGeographicPointFilter;
    boundingBox?: CSNBoundingBox;
    radius: number;

    // Pagination filters
    pageFilter: {
        // Start index (0-index)
        from: number;

        // Number of results to return
        size: number;
    };

    // Filter results by numer of guest rooms available on property
    guestRoomsFilter?: {
        min: number | null;
        max: number | null;
    };

    // Filter results by amenities available on property
    amenitiesFilter?: {
        amenities: CSNAmenity[];
    };

    areasFilter?: {
        areas: CSNAreaType[];
    };
}

/**
 * Abstracted interface for the CSN venue search output
 * NOTE: the output of the CSN venue search API is complex and detailed, this type only outlines
 * a subset of the fields interesting for our application
 */
export interface CSNSearchVenueData {
    id: string;
    name: string;
    mainImage?: {
        original: string;
        large: string;
        medium: string;
        small: string;
        extraSmall: string;
    };
    brandName: string;
    chainName: string;
    yearBuild?: number;
    yearRenovate?: number;
    description?: string;
    airportDistance: {
        imperialValue: number;
        metricValue: number;
    };
    amenities: CSNAmenity[];
    preferredRating?: CSNVenueRating;
    city: string;
    stateProvinceCode: string | 'BLANK';
    tags: CSNVenueTag[];
    geographicLocation?: {
        lat: number;
        lon: number;
    };
    type: CSNVenueType;
    diamondLevel: CSNDiamondLevel;
    totalSleepingRooms: number;
    featuredLevel?: string /* it's possible that this should be type CSNDiamondLevel,
                                        but it's unclear, string should be sufficient for now */;
}

export interface CSNVenueSearchOutput {
    searchResult: {
        venueList: CSNSearchVenueData[];
        size: number;
        from: number;
        totalVenueHits: number;
    };
}

export interface CSNVenueProfileServiceInput {
    venueIds: string[];
}

export enum CSNVenueRoomType {
    SINGLE = 'SINGLE',
    DOUBLE = 'DOUBLE',
    SUITE = 'SUITE',
}

export enum CSNImageCategory {
    UNCATEGORIZED = 'UNCATEGORIZED',
    EXTERIOR = 'EXTERIOR',
    FLOOR_PLAN = 'FLOOR_PLAN',
    MEETING_SPACE = 'MEETING_SPACE',
    LOBBY = 'LOBBY',
    AREA_MAP = 'AREA_MAP',
    LOCAL_ATTRACTIONS = 'LOCAL_ATTRACTIONS',
    RESTAURANT_LOUNGE = 'RESTAURANT_LOUNGE',
    RECREATION = 'RECREATION',
    INTERIOR = 'INTERIOR',
    SLEEPING_ROOM = 'SLEEPING_ROOM',
    MISCELLANEOUS = 'MISCELLANEOUS',
    ATTRACTION = 'ATTRACTION',
    ROOM = 'ROOM',
    GUEST_ROOM = 'GUEST_ROOM',
    CITYSCAPES = 'CITYSCAPES',
    PLACES_OF_INTEREST = 'PLACES_OF_INTEREST',
    HISTORIC_VENUES = 'HISTORIC_VENUES',
    MUSEUMS_MONUMENTS = 'MUSEUMS_MONUMENTS',
    HOTELS_RESORTS = 'HOTELS_RESORTS',
    PARKS_LANDSCAPES = 'PARKS_LANDSCAPES',
    GOLF_COURSES = 'GOLF_COURSES',
    BEACHES = 'BEACHES',
    PROMOTIONS = 'PROMOTIONS',
}

export interface CSNVenueProfileImage {
    group?: CSNImageCategory;
    name?: string;
    description?: string;
    imageURL: {
        original: string;
        large: string;
        medium: string;
        small: string;
        extraSmall: string;
    };
}

export interface CSNVenueProfileAmenities {
    amenityType: string;
    amenityNameList: string[];
    typeAmenity: string;
    amenityList: string[];
}

export interface SocialTableRoomData {
    socialtablesRoomName: string;
    basicFloorPlanFlag: boolean;
    basicFloorPlanImageUrlSmall: string;
    basicFloorPlanImageUrlLarge: string;
    basicFloorPlanPdfUrl: string;
}

export interface CSNVenueProfileMeetingRooms {
    name: string;
    socialTables?: SocialTableRoomData;
}

export interface RoomItemProps {
    name: string;
    socialTables: SocialTableRoomData;
}

export interface CSNGuestRoom {
    totalRoomCount?: number;
    roomDetails: {
        type: CSNVenueRoomType;
        count: number;
        maxRate?: number;
        minRate?: number;
    }[];
}

export interface CSNVenueProfile {
    images: CSNVenueProfileImage[];
    basicProfile: {
        venueDescription?: string;
        socialTablesEnabled: boolean;
        socialTablesProviderId: string;
        offeringStatus?: string;
        id?: string;
        diamondLevel: CSNDiamondLevel;
    };
    guestRoom?: CSNGuestRoom;
    highlights?: {
        chainName?: string;
        brandName?: string;
        // API can return  0 for buildYear | renovationYear
        buildYear?: number;
        renovationYear?: number;
    };
    closestAirports?: {
        airportDistance: {
            imperialValue: number;
            metricValue: number;
        };
        isVenueDefault: boolean;
    }[];
    amenities?: CSNVenueProfileAmenities[];
    meetingRooms?: CSNVenueProfileMeetingRooms[];
}

export type CSNQueryParserAutoSuggestion = {
    queryString: string;
    provider: string;
    types: string[];
    id: string;
};

interface CSNQueryParserInputBase {
    language?: string; // "EN"
    region?: string; // "US"
    useAlternateEnvironment?: boolean;
}

interface CSNQueryParserInputQueryString extends CSNQueryParserInputBase {
    queryString: string;
}

interface CSNQueryParserInputAutoSuggestion extends CSNQueryParserInputBase {
    autosuggestion: CSNQueryParserAutoSuggestion;
}

export type CSNQueryParserInput = CSNQueryParserInputQueryString | CSNQueryParserInputAutoSuggestion;

export type CSNGeographicPoint = {
    lat: number;
    lon: number;
};

export type CSNBoundingBox = {
    northEastPoint: CSNGeographicPoint;
    southWestPoint: CSNGeographicPoint;
};

type CSNBaseFilter = {
    type: string;
    maximum?: number | null;
    minimum?: number | null;
    measurementUnit?: string;
    values?: string[];
};

export interface CSNGeographicPointFilter extends CSNBaseFilter {
    geographicPoint: CSNGeographicPoint;
}

export interface CSNBoundingBoxFilter extends CSNBaseFilter {
    boundingBox: CSNBoundingBox;
}

export interface CSNQueryParserResult {
    queryString: string;
    formattedSearchText: string;
    initialGeographicFilter: string;
    confident: boolean;
    addressTypes: string[];
    countryCode: string;
    // filters
    // boundingBoxFilter is always in result because its one of the default filters for next csn requests
    boundingBoxFilter: CSNBoundingBoxFilter;
    // geographicPointFilter is always in result because its main object for geo based next csn requests
    geographicPointFilter: CSNGeographicPointFilter;
    additionalFilters?: CSNGeographicPointFilter[];
}

export interface CSNService {
    venuesSearch: (input: CSNVenueSearchInput) => Promise<CSNVenueSearchOutput>;
    getVenueProfiles: (input: CSNVenueProfileServiceInput) => Promise<CSNVenueProfile[]>;
    queryParser: (input: CSNQueryParserInput) => Promise<CSNQueryParserResult>;
}
