const readCookie = (value: string) => {
    const cookieValue = value[0] === '"' ? value.slice(1, -1) : value;
    return cookieValue.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
};

export const getCookieValue = (key: string) => {
    if (typeof document === 'undefined' || !key) {
        return null;
    }
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    const lookup = cookies.find((cookie) => {
        const parts = cookie.split('=');
        try {
            return key === decodeURIComponent(parts[0]);
        } catch (e) {
            console.error(e);
            return false;
        }
    });

    return readCookie(lookup ? lookup.split('=').slice(1).join('=') : '');
};
