// These are the implied search filters for the CSN search service to ensure venues are

import { CSNGeographicPointFilter, CSNBoundingBox } from '../types';

const defaultOccurrence = {
    occurrenceType: 'SHOULD',
    type: 'WEDDING_SPOT_SLEEPING_ROOMS_ENABLED',
    values: ['true'],
};

type Occurrence = typeof defaultOccurrence;
type SortType = {
    type: 'DISTANCE' | 'CVENT_PICKS';
    boundingBox?: CSNBoundingBox;
};

type ImplicitSearchInputResult = {
    filters: [CSNGeographicPointFilter, Occurrence];
    includeRanges: boolean;
    sort: SortType;
};

// relevant to their use on Wedding Spot
export const getImplicitSearchInput = (geoFilter: CSNGeographicPointFilter, boundingBox?: CSNBoundingBox): ImplicitSearchInputResult => {
    const sort: SortType =
        geoFilter.type === 'POLYGON'
            ? {
                  boundingBox,
                  type: 'CVENT_PICKS',
              }
            : // geoFilter.type === 'MAXIMUM_DISTANCE' for default non polygon geoFilter
              {
                  // TODO:  Make sure this is right
                  type: 'DISTANCE',
              };
    return {
        filters: [
            geoFilter,
            {
                ...defaultOccurrence,
            },
        ],
        includeRanges: false,
        sort,
    };
};
