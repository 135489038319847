import { Auth } from './auth';

export enum AuthScheme {
    CSRF = 1,
    OAUTH,
}

export interface OauthToken {
    token_type: string;
    scope: string;
    refresh_token: string;
    access_token: string;
    expires_in: number;
    foo: boolean;
}

export interface ApiClientConfig {
    apihost?: string;
    apiport?: number;
    apiprotocol?: string;
    apibaseurl?: string;
    apiclientid?: string;
    authenticationScheme?: AuthScheme;
    oauthToken?: OauthToken;
    headers?: { [key: string]: string };
}

export interface AuthApi {
    // Login using the legacy django flow (drf now)
    login: (userName: string, password: string) => Promise<void>;
    isLoggedIn: (queryParams?: {}) => Promise<'authed' | 'anonymous'>;
    userInfo: (queryParams?: {}) => Promise<Auth.UserInfo>;
    checkEmailAvailable: (email: string) => Promise<void>;
    connectAccount: (password: string) => Promise<void>;
    vendorSignup: (data: Auth.VendorSignupData) => Promise<void>;
    createAccount: (request: Auth.CreateAccountRequest) => Promise<ApiDetailResponse>;
    extendSession: () => Promise<void>;
    logout: () => Promise<void>;
}

export interface BaseApiClient {
    create: (path: string, data: Object, config?: ApiClientConfig) => Promise<Object>;
    bulkCreate: (path: string, data: Object[], config?: ApiClientConfig) => Promise<Object[]>;
    retrieve: (path: string, urlparams: Object, config?: ApiClientConfig) => Promise<Object>;
    update: (path: string, data: Object, config?: ApiClientConfig) => Promise<Object>;
    bulkUpdate: (path: string, data: Object[], config?: ApiClientConfig) => Promise<Object[]>;
    remove: (path: string, urlparams: Object, config?: ApiClientConfig) => Promise<Object>;
}

export interface ApiResponse<T> {
    status: number;
    content: T;
}

export interface ApiErrorResponse extends ApiDetailResponse {
    errors?: Object;
}

export interface ApiDetailResponse {
    detail: string;
}

export interface ApiEndpointOptions {
    unpackPage?: boolean;
    partial?: boolean;
}

// Wrap Axios errors in this standard container
export interface WrapperErrorResponse<D> {
    detail: D; // Data or message
    status: string | number | undefined; // http status code
}

export const NETWORK_ERROR = 'Network Error';
