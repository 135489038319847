/* eslint-disable class-methods-use-this */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosError } from 'axios';
import { AuthScheme, WrapperErrorResponse } from '../types/authApi';
import { getCookieValue } from './CookieWorker';
import { ApiClientConfiguration, ApiWorkerOptions, IApiWorker } from './types';

export class ApiWorker implements IApiWorker {
    private readonly _configuration: ApiClientConfiguration;

    private readonly _authHeaders: Partial<Record<'Authorization' | 'X-CSRFToken', string>> = {};

    constructor(config: ApiClientConfiguration) {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        this._configuration = config;

        if (this._configuration.authenticationScheme === AuthScheme.OAUTH) {
            if (this._configuration.oauthToken) {
                this._authHeaders.Authorization = `Bearer ${this._configuration.oauthToken.access_token}`;
            } else {
                throw new Error(
                    'Oauth configured, but could not find a token to authorize with. Did you mean to call auth.oauthLogin first?'
                );
            }
        } else {
            const cookie = getCookieValue('csrftoken');
            if (cookie) {
                this._authHeaders['X-CSRFToken'] = cookie;
            }
        }
        this._configuration.headers = { ...this._configuration.headers, ...this._authHeaders };
    }

    private _combineURLs(baseURL: string, relativeURL: string) {
        return relativeURL ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}` : baseURL;
    }

    private _getFullBaseUrl(path: string): string {
        return `${this._combineURLs(this._configuration.apiBaseUrl, path)}/`;
    }

    private _parseError({ response, message, code }: AxiosError): WrapperErrorResponse<any> {
        if (response && response.data) {
            return {
                detail: response.data,
                status: response?.status,
            };
        }

        return {
            detail: message,
            status: code,
        };
    }

    create<T, U>(path: string, data: U, additionalHeaders?: { 'X-Ws-AttachSession': string }) {
        return new Promise<T>((resolve, reject) => {
            axios
                .post<{ content: T }>(this._getFullBaseUrl(path), data, {
                    headers: { ...this._configuration.headers, ...additionalHeaders },
                })
                .then(({ data: responseData }) => resolve(responseData.content))
                .catch((err: AxiosError) => {
                    reject(this._parseError(err));
                });
        });
    }

    update<T, U>(path: string, data: U, additionalHeaders?: { 'X-Ws-AttachSession': string }) {
        return new Promise<T>((resolve, reject) => {
            axios
                .put<{ content: T }>(this._getFullBaseUrl(path), data, {
                    headers: { ...this._configuration.headers, ...additionalHeaders },
                })
                .then(({ data: responseData }) => resolve(responseData.content))
                .catch((err: AxiosError) => {
                    reject(this._parseError(err));
                });
        });
    }

    retrieve<T>(path: string, options?: ApiWorkerOptions | undefined) {
        return new Promise<T>((resolve, reject) => {
            axios
                .get<{ content: T & { results: T } }>(this._getFullBaseUrl(path), {
                    headers: { ...(this._configuration.headers as NonNullable<{ [key: string]: string }>) },
                    params: options?.urlParams,
                })
                .then(({ data }) => {
                    if (options?.additionalOptions?.unpackPage) {
                        resolve(data.content.results);
                    } else {
                        resolve(data.content);
                    }
                })
                .catch((err: AxiosError) => {
                    reject(this._parseError(err));
                });
        });
    }

    remove<T>(path: string, data?: any, options?: ApiWorkerOptions | undefined) {
        return new Promise<T>((resolve, reject) => {
            axios
                .delete<{ content: T }>(this._getFullBaseUrl(path), {
                    data,
                    headers: { ...(this._configuration.headers as NonNullable<{ [key: string]: string }>) },
                    params: options?.urlParams,
                })
                .then(({ data: responseData }) => resolve(responseData.content))
                .catch((err: AxiosError) => reject(this._parseError(err)));
        });
    }

    apiConfiguration() {
        return Object.freeze({ ...this._configuration });
    }
}
