export class ApiConsumerBase {
    get ApiWorker() {
        if (!this._apiWorker) {
            throw new Error('Consumer is not configured.');
        }
        return this._apiWorker;
    }
    configure(apiWorker) {
        this._apiWorker = apiWorker;
    }
}
