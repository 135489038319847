/**
 * Destination weddings types
 */

export namespace DWT {
    export enum DWTRoles {
        BRIDE = 1,
        GROOM,
        PARENT,
        OTHER,
    }

    export enum CountryOfResidence {
        US = 1,
        CANADA = 2,
    }

    export interface DWTQuoteRequestParams {
        // Always required
        venue_id: number;
        message: string;
        role: DWTRoles;
        country: CountryOfResidence;
        partner1_role: DWTRoles;
        partner1_first_name: string;
        partner1_last_name: string;
        partner2_role: DWTRoles;
        partner2_first_name: string;
        partner2_last_name: string;

        // Sometimes required (auth vs. unauth)
        first_name?: string;
        last_name?: string;
        email?: string;
        phone_number?: string;
        guest_count?: number;
        preferred_wedding_date?: string;
        dates_flexible?: boolean;
    }
}
