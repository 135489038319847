import { HoganConfig } from "../ApiWorker";

const HOGAN_CONFIG: HoganConfig =
    typeof process.env.HOGAN_CONFIG === 'string'
        ? (JSON.parse(process.env.HOGAN_CONFIG as string) as unknown as HoganConfig)
        : (process.env.HOGAN_CONFIG as unknown as HoganConfig);

export const getCSNSettings = () => {

    const CSN_ENV = HOGAN_CONFIG.csn.search_service.env;

    const CSN_VENUE_PROFILE_HOST = HOGAN_CONFIG.csn.venue_profile_service.base_url;
    const CSN_VENUE_PROFILE_PATH = 'venue-profile/v1/venue/fullProfiles';
    const CSN_VENUE_PROFILE_ENV = CSN_ENV;

    const CSN_VENUE_SEARCH_HOST = HOGAN_CONFIG.csn.search_service.base_url;
    const CSN_VENUE_SEARCH_PATH = 'csn-search/v1/search/venues';
    const CSN_VENUE_SEARCH_ENV = CSN_ENV;

    const CSN_QUERY_PARSER_HOST = HOGAN_CONFIG.csn.search_service.base_url;
    const CSN_QUERY_PARSER_PATH = 'csn-search/v1/parseQuery';
    const CSN_QUERY_PARSER_ENV = CSN_ENV;

    return {
        CSN_QUERY_PARSER_ENV,
        CSN_QUERY_PARSER_HOST,
        CSN_QUERY_PARSER_PATH,

        CSN_VENUE_PROFILE_ENV,
        CSN_VENUE_PROFILE_HOST,
        CSN_VENUE_PROFILE_PATH,

        CSN_VENUE_SEARCH_ENV,
        CSN_VENUE_SEARCH_HOST,
        CSN_VENUE_SEARCH_PATH,
    };
};
