import { WsApiClientRegistry } from '../ApiClientRegistry';
import { Navigation } from '../types';
import { ApiConsumerBase } from './ApiConsumerBase';

class NavigationConsumer extends ApiConsumerBase {
    getHeaderData(venueId?: number, isRDFlow?: boolean) {
        return this.ApiWorker.retrieve<Navigation.SiteHeaderData>(`/site-header`, {
            urlParams: { is_rd_flow: isRDFlow, venue_id: venueId },
        });
    }
}

export const NavigationApi = new NavigationConsumer();
WsApiClientRegistry.register(NavigationApi);
