import { WsApiClientRegistry } from '../ApiClientRegistry';
import { Flags } from '../types';
import { ApiConsumerBase } from './ApiConsumerBase';

class FlagsConsumer extends ApiConsumerBase {
    getFlags() {
        return this.ApiWorker.retrieve<Array<Flags.Flag>>(`/user/test-flags`);
    }
}

export const FlagsApi = new FlagsConsumer();
WsApiClientRegistry.register(FlagsApi);
